.promotion-banner {
    position: relative;
    box-sizing: border-box;
    width: 300px;
    height: auto;
    margin: 50px auto 0;
    border-radius: 10px;
    box-shadow: 2px 2px 15px 0px #0e0c1b
}

.ticket {
    position: relative;
    box-sizing: border-box;
    width: 300px;
    height: 100%;
    margin: 30px auto 0;
    margin-bottom: 50px;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    background: #fbfbfb;
    box-shadow: 2px 2px 15px 0px #979393
}

.ticket-bigger {
    position: relative;
    box-sizing: border-box;
    margin: 30px auto 0;
    margin-bottom: 50px;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    background: #fbfbfb;
    box-shadow: 2px 2px 15px 0px #979393
}

.ticket-monthly {
    border-radius: 5px;
    background: #eaeaea;
    padding: 10px;
}

.ticket-success {
    border-radius: 5px;
    background: #28a745;
    color: white;
    padding: 10px;
}

.ticket-danger {
    border-radius: 5px;
    background: #dc3545;
    color: white;
    padding: 10px;
}

.ticket-warning {
    border-radius: 5px;
    background: #ffc107;
    padding: 10px;
}

.img-radius-5 {
    border-radius: 5px;
}

.img-radius-10 {
    border-radius: 10px;
}

img {
    display: block;
    margin: 0 auto;
}

.img-block {
    width: 100%;
}

.height-100 {
    height: 100px;
}

.height-80 {
    height: 80px;
}

.width-100 {
    width: 100px;
}

.height-200 {
    height: 200px;
}

.height-250 {
    height: 250px;
}

.height-300 {
    height: 300px;
}

.margin-0 {
    margin: 0;
}

.top-10 {
    margin-top: 10px;
}

.top-20 {
    margin-top: 20px;
}

.font-20 {
    font-size: 20px;
}

.font-30 {
    font-size: 30px;
}

.font-40 {
    font-size: 40px;
}

.text-bold {
    font-weight: bold;
}

.text-success {
    color: #28a745;
}

.text-danger {
    color: #ff0000;
}

.ticket:before,
.ticket:after {
    content: "";
    position: absolute;
    left: 5px;
    height: 6px;
    width: 290px;
}

.ticket:before {
    top: -5px;
    background: radial-gradient(circle, transparent, transparent 50%, #fbfbfb 50%, #fbfbfb 100%) -7px -8px/16px 16px repeat-x;
}

.ticket:after {
    bottom: -5px;
    background: radial-gradient(circle, transparent, transparent 50%, #fbfbfb 50%, #fbfbfb 100%) -7px -2px/16px 16px repeat-x;
}

.ticket__content {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border: 6px solid #d8d8d8;
}

a {
    text-decoration: none;
    color: black
}

.ticket__text {
    width: 400px;
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 3rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #c6dede;
    transform: translate(-25px, 25px) rotate(-55deg);
}

.ticket-number {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
}

div {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.font-rubik-w-600 {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.text-bold {
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.text-qrcode {
    line-break: anywhere;
}

.text-size-20 {
    font-size: 20px;
}

.text-input-rounded {
    width: 98%;
    height: 45px;
    text-align: center;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    border-radius: 22.5px;
    font-size: 17px;
}

.loader {
    width: 30px;
    height: 30px;
    border: 3px solid #38373e;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: 30px 0 0 0
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-card {
    font-weight: bold;
    background: #28a745;
    color: white;
    font-size: 20px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    border: 0px;
    cursor: pointer;
}

.btn-orange {
    font-weight: bold;
    background: #CA8301;
    color: white;
    font-size: 20px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    border: 0px;
    cursor: pointer;
}

.btn-red {
    background: #ff0000;
    color: white;
    font-size: 20px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    border: 0px;
    cursor: pointer;
}

.btn-gray {
    background: #c8c9c8;
    color: white;
    font-size: 20px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    border: 0px;
    cursor: pointer;
}